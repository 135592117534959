import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import oranges from "../assets/images/oranges.jpg";
import mango from "../assets/images/mango.jpg";
import mango1 from "../assets/images/mango1.jpg";
import mango2 from "../assets/images/mango2.jpg";
import grape1 from "../assets/images/grape1.jpg";
import grape2 from "../assets/images/grape2.jpg";
import pomegranate from "../assets/images/pomegranate.jpg";
import valencia from "../assets/images/valencia.jpg";
import tangerine from "../assets/images/tangerine.jpg";

// Styled Components
const ProductsWrapper = styled.div`
  padding: 50px 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  text-align: center;
  color: #1a501b;
  font-size: 2.5rem;
  margin-bottom: 40px;
  font-family: "Arial", sans-serif;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
`;

const Card = styled(motion.div)`
  width: 300px;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
  }
`;

const CardImage = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  ${Card}:hover & img {
    transform: scale(1.1);
  }
`;

const CardContent = styled.div`
  padding: 20px;
  text-align: center;
`;

const CardTitle = styled.h2`
  font-size: 1.5rem;
  color: #1a501b;
  margin: 10px 0;
  font-family: "Arial", sans-serif;
`;

const CardDescription = styled.p`
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  font-family: "Arial", sans-serif;
`;

const VarietyList = styled.ul`
  margin-top: 10px;
  list-style-type: disc;
  text-align: left;
  padding: 0 20px;
`;

// Fruit data structure
const fruits = [
  {
    category: "Citrus",
    types: [
      {
        name: "Navel Oranges",
        image: oranges,
        description: "Fresh and juicy oranges packed with vitamin C.",
        varieties: ["Navel Washington", "Navelina", "Lanelate"],
      },
      {
        name: "Valencia Oranges",
        image: valencia,
        description: "Perfect for juicing and refreshing drinks.",
        varieties: [],
      },
      {
        name: "Murcott Mandarins",
        image: tangerine,
        description: "Sweet and easy-to-peel mandarins.",
        varieties: [],
      },
    ],
  },
  {
    category: "Grapes",
    types: [
      {
        name: "White Grapes",
        image: grape1,
        description: "Crisp, sweet, and seedless grapes.",
        varieties: ["Superior Grapes"],
      },
      {
        name: "Red Grapes",
        image: grape2,
        description: "Bold and flavorful seedless red grapes.",
        varieties: ["Flame Seedless"],
      },
    ],
  },
  {
    category: "Mangoes",
    types: [
      {
        name: "Jasmine Mango",
        image: mango,
        description: "Delicious tropical mangoes with a rich taste.",
        varieties: [],
      },
      {
        name: "Shelly Mango",
        image: mango1,
        description: "Sweet and tangy, perfect for smoothies.",
        varieties: [],
      },
      {
        name: "Keitt Mango",
        image: mango2,
        description: "Juicy and fiberless mango variety.",
        varieties: [],
      },
    ],
  },
  {
    category: "Pomegranate",
    types: [
      {
        name: "Wonderful Pomegranate",
        image: pomegranate,
        description: "Bright, juicy seeds full of flavor and nutrients.",
        varieties: [],
      },
    ],
  },
];

const Products = () => {
  return (
    <ProductsWrapper>
      <Title>Our Fresh Fruits</Title>
      <CardContainer>
        {fruits.flatMap((fruit) =>
          fruit.types.map((type, index) => (
            <Card
              key={index}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <CardImage>
                <img src={type.image} alt={type.name} />
              </CardImage>
              <CardContent>
                <CardTitle>{type.name}</CardTitle>
                <CardDescription>{type.description}</CardDescription>
                {type.varieties.length > 0 && (
                  <VarietyList>
                    {type.varieties.map((variety, idx) => (
                      <li key={idx}>{variety}</li>
                    ))}
                  </VarietyList>
                )}
              </CardContent>
            </Card>
          ))
        )}
      </CardContainer>
    </ProductsWrapper>
  );
};

export default Products;
