import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Styled Components
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1a501b;
  padding: 10px 20px;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  height: 50px;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    display: ${(props) => (props.open ? 'flex' : 'none')};
    width: 100%;
    background-color: #1a501b;
    padding: 10px 0;
  }
`;

const NavLink = styled(motion.a)`
  margin: 0 15px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  &:hover {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Bar = styled.div`
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
`;

const HeaderComponent = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Header>
      <Logo src="nile-roots2.png" alt="Nile Roots Logo" />
      <Hamburger onClick={() => setMenuOpen(!menuOpen)}>
        <Bar />
        <Bar />
        <Bar />
      </Hamburger>
      <Nav open={menuOpen}>
        <NavLink href="/" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
          Home
        </NavLink>
        <NavLink href="/about" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
          About
        </NavLink>
        <NavLink href="/products" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
          Products
        </NavLink>
  
        <NavLink href="/contact" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
          Contact Us
        </NavLink>
      </Nav>
    </Header>
  );
};

export default HeaderComponent;
