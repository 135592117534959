import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Products from './Products';
import Contact from './Contact';
import About from './About';

// Styled Components for the page
const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

const Section = styled(motion.section)` /* Changed section to motion for animations */
  width: 100%;
  padding: 100px 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.bgColor || '#ffffff'};
   background-image: ${(props) => props.bgImage ? `url(${props.bgImage})` : 'none'};
  color: #333;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  transition: background-color 0.3s ease;
  overflow: hidden;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: 700;
  color: #1a501b;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
`;

const ScrollButton = styled.button`
  background-color: #1a501b;
  color: white;
  border: 2px solid #1a501b;
  padding: 15px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 30px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  font-weight: 600;

  &:hover {
    transform: scale(1.05);
    background-color: #ffcc00;
    border-color: #ffcc00;
  }

  &:active {
    transform: scale(0.95);
    background-color: #1a501b;
  }
`;

const Home = () => {
  const handleScroll = (id) => {
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <HomeWrapper>
      {/* Home Section */}
      <Section 
  id="home" 
  bgImage="https://cdn.pixabay.com/photo/2015/07/28/22/11/wheat-865152_1280.jpg"  // Background Image
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  transition={{ duration: 1.5, ease: 'easeInOut' }}
  style={{
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    position: 'relative',
    color: '#fff', 
    padding: '100px 20px', 
    textAlign: 'center'
  }}
>
  <motion.h1
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 1, delay: 0.5 }}
    style={{
      fontSize: '3rem', 
      fontWeight: '700', 
      textShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
      color: '#1a501b'
    }}
  >
    Welcome to Nile Roots
  </motion.h1>
  <motion.p
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 1, delay: 1 }}
    style={{ fontSize: '1.2rem',  fontWeight: '700', margin: '20px 0', color: '#1a501b',  textShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)', }}
  >
    Harvesting Freshness, Straight to Your Door
  </motion.p>
  <ScrollButton onClick={() => handleScroll('about')}>Find out more</ScrollButton>
</Section>
      {/* <Section 
        id="home" 
        bgImage="https://cdn.pixabay.com/photo/2015/07/28/22/11/wheat-865152_1280.jpg"  // Add your background image URL here
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5, ease: 'easeInOut' }}
      >
        <Title>Taste the Freshness of Every Season</Title>
        <ScrollButton onClick={() => handleScroll('about')}>Find out more</ScrollButton>
      </Section> */}

      {/* About Us Section */}
      <Section id="about" bgColor="#ffcc00">
        <Title>About Us</Title>
        <About />
        <ScrollButton onClick={() => handleScroll('products')}>Our Products</ScrollButton>
      </Section>

      {/* Products Section - Animated on Scroll */}
      <Section
        id="products"
        bgColor="#f7f7f7"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.2 }}
      >
        <Title>Our Products</Title>
        <Products />  {/* Embed Products component here */}
        <ScrollButton onClick={() => handleScroll('contact')}>Contact Us</ScrollButton>
      </Section>

      {/* Contact Us Section */}
      <Section id="contact" bgColor="#8DB63E">
        <Title>Contact Us</Title>
        <Contact />
        <ScrollButton onClick={() => handleScroll('home')}>Back to Home</ScrollButton>
      </Section>
    </HomeWrapper>
  );
};

export default Home;
