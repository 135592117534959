import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Products from './pages/Products';
import Contact from './pages/Contact';
import styled from 'styled-components';
import About from './pages/About';
import HeaderComponent from './components/Header';

const Footer = styled.footer`
  background-color: #1a501b;
  color: white;
  text-align: center;
  padding: 20px;
  position: relative;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
`;

const App = () => {
  return (
    <Router>
      <div>
        {/* Header Section */}
        <HeaderComponent/>

        {/* Main Content */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
        </Routes>

        {/* Footer Section */}
        <Footer>
          <p>&copy; {new Date().getFullYear()} Nile Roots. All rights reserved.</p>
        </Footer>
      </div>
    </Router>
  );
};

export default App;