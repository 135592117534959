import React from 'react';
import styled from 'styled-components';

// Styled components
const Wrapper = styled.div`
  padding: 50px;
  background-color: #f7f7f7;
  min-height: 100vh;
`;

const Title = styled.h1`
  text-align: center;
  color: #1a501b;
  margin-bottom: 20px;
`;

const Section = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
  line-height: 1.6;
  color: #333;
`;

const SubTitle = styled.h2`
  color: #1a501b;
  margin-top: 30px;
`;

const About = () => {
  return (
    <Wrapper>
      <Title>About Us</Title>
      <Section>
        <p>
          At <strong>Nile Roots</strong>, we are a dynamic and forward-thinking company, committed to bridging markets and delivering the finest products all the way from the Nile River roots to global destinations. Our mission is to streamline international trade by providing high-quality products, seamless logistics, and personalized services to meet the needs of businesses worldwide.
        </p>
        <p>
          Founded in 2024, we specialize in exporting agricultural products leveraging our deep industry knowledge and network of trusted partners. We are passionate about helping businesses expand their reach, grow their market presence, and succeed on a global scale.
        </p>
        <p>
          Our team is comprised of experts in international trade, logistics, and compliance, ensuring every transaction is efficient, transparent, and compliant with international standards. We pride ourselves on offering tailored solutions that meet the unique demands of each market, while maintaining a commitment to quality, sustainability, and customer satisfaction.
        </p>
        <p>
          At <strong>Nile Roots</strong>, we believe that international trade is more than just business—it’s about fostering relationships, connecting cultures, and creating value for everyone involved.
        </p>
        <SubTitle>Vision</SubTitle>
        <p>
          To be a leading global export company, recognized for delivering exceptional quality and innovative solutions that connect businesses across borders, fostering economic growth and creating sustainable opportunities for global trade.
        </p>
        <SubTitle>Mission</SubTitle>
        <p>
          Our mission is to simplify and accelerate international trade by providing high-quality products, efficient logistics, and personalized service. We strive to build strong, long-term relationships with our clients and partners while ensuring compliance with global standards. Through innovation and dedication, we aim to expand access to new markets, contribute to sustainable economic development, and support the growth of businesses worldwide.
        </p>
      </Section>
    </Wrapper>
  );
};

export default About;
