import React from "react";
import styled from "styled-components";
import {
  FaFacebook,
  FaInstagram,
  FaEnvelope,
  FaPhoneAlt,
  // FaMapMarkerAlt,
} from "react-icons/fa";

// Styled Components
const ContactWrapper = styled.div``;

// const Title = styled.h1`
//   text-align: center;
//   color: #1a501b;
//   margin-bottom: 40px;
//   font-size: 2.5rem;
// `;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
`;

const InfoItem = styled.div`
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoLabel = styled.span`
  font-weight: bold;
  color: #1a501b;
  margin-right: 10px;
`;

const InfoValue = styled.span`
  color: #555;
`;

const Icon = styled.div`
  margin-right: 10px;
  font-size: 1.5rem;
  color: #1a501b;
`;

const SocialMedia = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
`;

const SocialLink = styled.a`
  color: #1a501b;
  font-size: 2rem;
  transition: all 0.3s ease;
  &:hover {
    color: #3c2415;
    transform: scale(1.2);
  }
`;

const InfoValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Contact = () => {
  return (
    <ContactWrapper>
      <ContactInfo>
        <InfoItem>
          <Icon>
            <FaEnvelope />
          </Icon>
          <InfoLabel>Email: </InfoLabel>
          <InfoValue>
            <a href="mailto:info@nile-roots.com">info@nile-roots.com</a>
          </InfoValue>
        </InfoItem>
        <InfoItem>
          <Icon>
            <FaPhoneAlt />
          </Icon>
          <InfoLabel>Phone: </InfoLabel>
          <InfoValueContainer>
            <InfoValue>
              <a href="tel:+201221471114">+201221471114</a>
            </InfoValue>
            <InfoValue>
              <a href="tel:+201151167558">+201151167558</a>
            </InfoValue>
          </InfoValueContainer>
        </InfoItem>
        {/* <InfoItem>
          <Icon>
            <FaMapMarkerAlt />
          </Icon>
          <InfoLabel>Address: </InfoLabel>
          <InfoValue>123 Nile Roots St, Cairo, Egypt</InfoValue>
        </InfoItem> */}
      </ContactInfo>

      {/* Social Media Links */}
      <SocialMedia>
        <SocialLink href="https://www.facebook.com/nileroots" target="_blank">
          <FaFacebook />
        </SocialLink>
        <SocialLink href="https://www.instagram.com/nileroot" target="_blank">
          <FaInstagram />
        </SocialLink>
      </SocialMedia>
    </ContactWrapper>
  );
};

export default Contact;
